:root {
  --primary-color: #ff6a32;
  --bs-primary-rgb: 253, 106, 50;
}

.bg-primary {
  background-color: var(--primary-color) !important;
  color: #ffffff !important;
}

.bg-primary .heading-title.text-primary {
  color: #ffffff !important;
}

.bg-primary .heading-title {
  color: #000000;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ff6a32;
  --bs-btn-border-color: #ff6a32;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #fe784b;
  --bs-btn-hover-border-color: #fe784b;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #fe784b;
  --bs-btn-active-border-color: #fe784b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ff6a32;
  --bs-btn-disabled-border-color: #ff6a32;
}

.navbar-brand {
  font-weight: 700;
}

.header-link {
  color: #ffffff;
  display: block;
  padding: 10px;
}

.bg-image {
  background-image: url('assets/prm_online_login_image.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 0;
  padding-top: 53%;
  position: relative;
}

.main-session {
  position: absolute;
  top: 35%;
  left: 10%;
  width: 1000px;
  text-align: left;
}

.heading-title {
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
  line-height: 56px;
}

.subTitle {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

p {
  font-size: 18px;
}

.light-background {
  background-color: #f5f6f8;
}

.bold {
  font-weight: bold;
}
